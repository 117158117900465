import BaseController   from "controllers/accounts/base"
import inspectionLookup from "widgets/lookups/inspection_lookup"

class Controller extends BaseController {
  call() {
    super.call();

    new inspectionLookup(this.root)
  }
}

export default Controller;
