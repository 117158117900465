import Widget           from "lib/widget"
import AjaxForm         from "servos/ajaxForm"

class vehicleMovementsLocation extends Widget {
  bind() {
    bindLocationType.call(this)
    setDefaultLocationType.call(this)
    checkLocationType.call(this)
  }
}

let bindLocationType = function() {
  this.root.on('change', "input[name*='collection-location']", (e) => {
    checkLocationType.call(this)
  })
}

// Determines if we should select saved_address or other_address depending on existing data
//
let setDefaultLocationType = function() {
  // Check if selected address has a value, if not, default to saved address
  if (this.root.find('.address-block-other').find("input[name*='[address_1]']").val() == "") {
    this.root.find("input[name*='collection-location'].saved-address").click()
  } else {
    this.root.find("input[name*='collection-location'].other-address").click()
  }
}

// Hides/Shows location data depending on option selected
//
let checkLocationType = function() {
  let default_value = this.root.find("input[name*='collection-location']:checked").val()

  let saved_inputs = this.root.find('.address-block-saved').find('input, select')
  let other_inputs = this.root.find('.address-block-other').find('input, select')

  if (default_value == 'saved') {
    this.root.find('.address-block-saved').removeClass('d-none')
    this.root.find('.address-block-other').addClass('d-none')

    saved_inputs.each((index, value) => {
      $(value).attr('required', 'required')
    })

    
    enableDisableInputs.call(this, saved_inputs, true)
  } else {
    this.root.find('.address-block-saved').addClass('d-none')
    this.root.find('.address-block-other').removeClass('d-none')
    
    saved_inputs.each((index, value) => {
      $(value).removeAttr('required')
    })
    enableDisableInputs.call(this, other_inputs, true)
  }
}

// Sets/Removes disabled attr on inputs
//
let enableDisableInputs = function(inputs, enabled) {
  $.each(inputs, (index, value) => {
    $(value).prop('disabled', !enabled)
  })
}

export default vehicleMovementsLocation;
