import $              from "jquery"
import select2        from "select2"
import "select2/dist/css/select2.min.css"
import "select2-bootstrap-theme/dist/select2-bootstrap.min.css"

const defaultOptions = {
  placeholder: '',
  allowClear: true,
  width: '100%',
}

class Select2Widget {
  constructor(root, options = {}) {
    this.root = $(root)
    this.options = {
      ...defaultOptions,
      ...options
    }

    this.widget = $(root).select2(this.options)
  }

  clear() {
    this.widget.val('').trigger('change')
  }
}

export default Select2Widget
