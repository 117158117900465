import BaseController from "controllers/accounts/base"

class Controller extends BaseController {

  call() {
    super.call();
  }

}

export default Controller;