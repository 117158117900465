export default class Url {

  static getQueryParamValue(key) {
    let queryParams = window.location.search ? (window.location.search.substring(1) ? window.location.search.substring(1).split("&") : null) : null;

    if (!queryParams) return null;
    let value = null

    queryParams.forEach(function(queryParam) {
      let queryParamKey = queryParam.split("=")[0];
      if (queryParamKey === key) {
        value = queryParam.split("=")[1]
      }
    });
    return value
  }

}
