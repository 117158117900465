import Widget           from "lib/widget"
import AjaxForm         from "servos/ajaxForm"

class DistributionChannel extends Widget {
  constructor(root, url) {
    super()
    this.root = root
    this.url = url
    fetchData.call(this)
  }
}

let fetchData = function() {

  $.ajax({
    url: this.url,
    dataType: 'json',
    method: 'GET',
    data: '',
    success: (response) => {
      this.root.find('.distribution_channel_results').html(response.results_partial)
    }
  })
}

export default DistributionChannel;
