import BaseController           from "controllers/accounts/base";

class Controller extends BaseController {
  call() {
    super.call();
    this.initSaleTypeInputField();
  }

  initSaleTypeInputField() {
    $('#other_sale_type').hide();
    $('#known_sale_type').on('change', function() {
      if ($('#known_sale_type').val() === 'Other') {
        $('#other_sale_type').slideDown();
        $('#other_sale_type').attr('name', 'sale[sale_type]')
      } else {
        $('#other_sale_type').slideUp();
        $('#other_sale_type').attr('name', 'unused')
      }
    })
  }
}


export default Controller;