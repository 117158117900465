import AjaxForm from "servos/ajaxForm"
import { cacheButtonContent, enableButtons, disableButtons } from "servos/formHelpers"


class AjaxFileForm extends AjaxForm {
  doAjax() {
    const formData = new FormData(this.form[0])

    $.ajax({
      method: this.config["method"] || this.form.prop("method" || "POST"),
      url:    this.config["action"] || this.form.prop("action"),
      data:   formData,
      // Neccessary settings for sending the file
      contentType: false,
      processData: false,
      success:  success.bind(this),
      error:    error.bind(this),
      complete: complete.bind(this)
    })
  }
}

const success = function(data, status, jqXHR) {
  this.emit("success", {
    data: data,
    status: status,
    jqXHR: jqXHR
  })
}

const complete = function(jqXHR, status) {
  this.emit("complete", {
    jqXHR: jqXHR,
    status: status
  })
  if (!this.config.dontEnable) {
    enableButtons(this.buttons)
  }
}

const error = function(jqXHR, status, errorThrown) {
  if (jqXHR.status == 422) {
    this.emit("422", {
      jqXHR: jqXHR,
      status: status,
      errorThrown: errorThrown
    })
  }
  this.emit("error", {
    jqXHR: jqXHR,
    status: status,
    errorThrown: errorThrown
  })
}

export default AjaxFileForm
