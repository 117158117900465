import $ from "jquery";
import {Account} from "../models/account";

export class RestClient {


  constructor({host}) {
    this.host = host;
  }

  execute({method, path, queryParams = {}, payload = null}) {
    let fullUrl = this.assembleRootUrl(path) + this.assembleQueryParamsString(queryParams);

    this.request = $.ajax({
      type: method,
      url: fullUrl,
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      xhrFields: {
        withCredentials: true
      },
      data: payload
    });

    $(document.body).css({'cursor': 'wait'});

    return this
  }

  static execute({method, path, queryParams = {}, payload = null}) {
    return new RestClient({host: window.location.origin}).execute({method: method, path: path, queryParams: queryParams, payload: payload})
  }

  assembleRootUrl(path) {
    return this.host + "/accounts/" + Account.currentAccountName().toLowerCase() + "/" + path;
  }

  static getUrl(path) {
    return new RestClient({host: window.location.origin}).assembleRootUrl(path)
  }

  assembleQueryParamsString(queryParams) {
    return $.param(queryParams).length > 0 ? "?" + $.param(queryParams) : ""
  }

  success(onSuccessCallback) {
    this.request.done(function(data, message, jqXHR) {
      $(document.body).css({'cursor': 'default'});
      onSuccessCallback(jqXHR.status, data)
    });
    return this
  }

  failure(onFailureCallback) {
    this.request.fail(function(jqXHR, status) {
      $(document.body).css({'cursor': 'default'});
      onFailureCallback(jqXHR.status, jqXHR.responseJSON)
    });
    return this
  }

}
