import Widget           from "lib/widget";
import ImageWidget      from "widgets/inspection/image";

class Images extends Widget {
  bind() {
    bindAjaxForms.call(this)
  }
}

let bindAjaxForms = function() {
  _.each(this.root.find('.inspection-image-item'), (imageElem) => {
    new ImageWidget($(imageElem))
  })
}

export default Images;
