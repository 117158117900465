import Widget           from "lib/widget"
import AjaxForm         from "servos/ajaxForm"

class Accessories extends Widget {
  bind() {
    bindForm.call(this)
  }
}

let bindForm = function() {
  const form = this.root.find("form")

  if (form.length > 0) {
    const ajaxForm = new AjaxForm(form)
    ajaxForm.on("success",  handle_success.bind(this, form))
    ajaxForm.on("error",    handle_error.bind(this, form))
    bindCancelBtn.call(this)
  } else {
    this.root.find('.accessories-edit').on('click', (e) => {
      e.preventDefault();
      let url = e.currentTarget.href;

      $.ajax({
        url: url,
        method: "GET",
        dataType: "json"
      }).done((response) => {
        this.root.html(response.html);
        bindForm.call(this);
      })

    })
  }
}

let bindCancelBtn = function() {
  this.root.find('.btn-cancel').on('click', (e) => {
    e.preventDefault();
    let url = e.currentTarget.href;
    $.ajax({
      url: url,
      method: "GET",
      dataType: "json"
    }).done((response) => {
      this.root.html(response.html)
      bindForm.call(this)
    })    
  })
}

let handle_success = function(form, response) {
  this.root.html(response.data.html)
  bindForm.call(this)
}

let handle_error = function(form, response) {
  this.root.html(response.jqXHR.responseJSON.html)
  bindForm.call(this)
}

export default Accessories;
