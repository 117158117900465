import Widget           from "lib/widget"
import AjaxForm         from 'servos/ajaxForm'
import {RestClient}       from 'api/rest_client'

class InspectionDetails extends Widget {
  bind() {
    toggleAllowance.call(this);
  }
}

let toggleAllowance = function(){
  $('input:checkbox#toggle-allowance').prop('checked', $('input:checkbox#toggle-allowance').data('allowanceIncluded'))

  $('input:checkbox#toggle-allowance').on('change', (e) => {
    let payload = {allowance_included: $('input:checkbox#toggle-allowance').prop('checked')}
    let path = gon.current_inspection.urls.toggle_allowance

    $(document.body).css({'cursor': 'wait'});

    $.ajax({
      url: path,
      method: "PUT",
      dataType: 'json',
      data: payload
    }).done((response) => {
      window.location = response.redirectUrl;
      window.location.reload(true);
    }).fail((response) => {
      $(document.body).css({'cursor': 'default'});
    })
  })
}

export default InspectionDetails;
