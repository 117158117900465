import Widget from "lib/widget";

let filterDiv = "<div class='loading'><i class='fa fa-spinner fa-pulse'></i>&nbsp;&nbsp;Filtering...</div>"

let afterSuccess = function(data) {
  $('.loading').fadeOut()
  renderPagination.call(this, data)
  this.root.find('.paginated-list').html(data.listHtml)
  this.emit('pagination-complete', data)
}

let renderPagination = function(data) {
  this.pagination.html(data.paginationHtml)
  bindPagination.call(this, this.pagination)
}

let requestPagination = function(url) {
  $.ajax({
    url: url,
    dataType: 'JSON',
    beforeSend: () => {
      $('body').append(filterDiv)
    },
    success: (data) => {
      afterSuccess.call(this, data)
    },
    error: (xhr, text, error) => {
      swal({
        title: "Request Error",
        text: text,
        type: 'error',
        showCancelButton: false,
        confirmButtonText: "Close",
        showLoaderOnConfirm: true,
        allowOutsideClick: true,
        confirmButtonColor: "#D9534F",

      });
      $('.loading').fadeOut()
    }
  })
}

let bindPagination = function(pagination) {
  pagination.off()
  pagination.on('click', 'li:not(.disabled) a', (e) => {
    e.preventDefault();
    requestPagination.call(this, e.currentTarget.href)
  })
};

class KaminariPagination extends Widget {
  constructor(root) {
    super(root);
    this.pagination = this.root.find('.pagination-container')
    bindPagination.call(this, this.pagination)

    this.on('updated-list', (data) => {
      renderPagination.call(this, data);
    })
  }
}

export default KaminariPagination;
