import BaseController           from "controllers/accounts/base";
import KaminariPagination       from "servos/kaminariPagination"
import FilterForm               from "servos/filterForm";

class Controller extends BaseController {
  call() {
    super.call();
    bindSearchList.call(this);
  }
}

let bindSearchList = function() {
  this.filterForm = new FilterForm(this.root.find('.filtrable'))

  this.filterForm.on('updated-list', (data) => {
    this.kaminariPagination.trigger('updated-list', data);
  })

  this.kaminariPagination = new KaminariPagination(this.root.find('.paginatable'))

  this.kaminariPagination.on("pagination-complete", (data) => {
    // here if I need something on pagination complete
  })

  this.on('select2-changed', (e) => {
    this.filterForm.send()
  });

  this.filterForm.send()
}


export default Controller;
