import Widget           from "lib/widget"

class InspectableAccounts extends Widget {
  bind() {
    bindInspectableAccounts.call(this)
  }
}

let bindInspectableAccounts = function() {
  console.log('widget.bindInspectableAccounts')

  let container = this.root.find('.inspectable-accounts')

  if (container.length > 0) {
    let roleSelect = this.root.find("[name*='[role]']")

    roleSelect.on('change', (e) => {
      if (e.currentTarget.value != '') {
        toggleInspectableAccount.call(this, container, true)
      } else {
        toggleInspectableAccount.call(this, container, false)
      }
    })
    roleSelect.trigger('change')
  }
}

let toggleInspectableAccount = function(container, active) {
  console.log('toggleInspectableAccount')
  container.toggleClass('hidden', !active)
  container.find('input').prop('disabled', !active)    
}

export default InspectableAccounts;
