import BaseController from "controllers/backend/base";

class Controller extends BaseController {
  call() {
    super.call();

  }
}

export default Controller;
