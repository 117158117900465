import Widget           from "lib/widget"
import AjaxForm         from 'servos/ajaxForm'
import Swal            from 'sweetalert2'

class NewDamageItem extends Widget {
  bind() {
    bindForm.call(this);
  }

  submit() {
    this.root.submit();
    this.root.find('.submit-btn').addClass('disabled')
    this.root.find('.submit-btn').prop('disabled', 'disabled')
  }
}


let bindForm = function() {
  this.ajaxForm = new AjaxForm(this.root);
  this.ajaxForm.on("success",  success.bind(this));
  this.ajaxForm.on("error",    error.bind(this));

  this.root.find('.location-select').change((e) => {
    requestAssignedForm.call(this, 'location_selected')
  })

  this.root.find('.component-select').change((e) => {
    requestAssignedForm.call(this, 'component_selected')
  })

  this.root.find('.fault-select').change((e) => {
    requestAssignedForm.call(this, 'fault_selected')
  })

  this.root.find('.repair-action-select').change((e) => {
    requestAssignedForm.call(this, 'repair_action_selected')
  })

  this.root.find('.hours-input').on('change', (e) => {
    requestAssignedForm.call(this, 'hours_changed')
  })

  this.root.on("click", "#overwrite-price", (e) => {
    const checkboxChecked = e.target.checked
    checkboxChecked ? document.querySelector('input.retail-price-input').value = '' : null
  })
}

// assign values to form and re render the partial with new values.
// used to dynamically update select options
let requestAssignedForm = function(event) {
  let data = _.merge(objectifyForm(this.root), {event: event});
  let assignUrl = this.root.data().assignurl;

  this.root.html('<div class="spinner-container"><span class="fa fa-spinner fa-4x fa-spin"></span></div>')

  $.ajax({
    url: assignUrl,
    method: 'get',
    data: data,
    dataType: 'json'
  }).done((response) => {
    this.replaceHtml(response.html)
  })
};

//serialize data function
let objectifyForm = function(form) {
  let formArray = form.serializeArray();

  var returnArray = {};
  for (var i = 0; i < formArray.length; i++){
    returnArray[formArray[i]['name']] = formArray[i]['value'];
  }
  return returnArray;
}

let success = function(response) {
  $('#new-damage-item-modal').modal('hide');
  Swal.fire({
    title: "Item successfully created!",
    type: 'success'
  }).then((result) => {
    if (result.value) {
      window.location = response.redirectUrl;
      window.location.reload(true);
    }
  });
}

let error = function(response) {
  if (response.jqXHR.responseJSON && response.jqXHR.responseJSON.error_message) {
    Swal.fire({
      title: "Damage item could not be created.",
      text: response.jqXHR.responseJSON.error_message,
      type: 'error',
      confirmButtonText: "OK",
      allowOutsideClick: true
    });
  }
  this.replaceHtml(response.jqXHR.responseJSON.html)
}

export default NewDamageItem;
