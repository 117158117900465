const cacheButtonContent = function(buttons) {
  buttons.each(function() {
    const button = $(this)
    if (button.prop("tagName") == "INPUT" ) {
      button.prop("originalValue", button.val())
    } else {
      button.prop("originalHtml", button.html())
    }
  })
}

const disableButtons = function(buttons) {
  buttons.each(function() {
    const button = $(this)
    const disableWith = button.data("disable-with")

    if (disableWith) {
      if (button.prop("tagName") == "INPUT" ) {
        button.val(disableWith)
      } else {
        button.html(disableWith)
      }
    }
  })

  buttons.prop("disabled", true)
}

const enableButtons = function(buttons) {
  buttons.each(function() {
    const button = $(this)

    if (button.prop("tagName") == "INPUT" ) {
      const val = button.prop("originalValue")
      button.val(val)
    } else {
      const html = button.prop("originalHtml")
      button.html(html)
    }
  });
  buttons.prop("disabled", false)
}

const scrollToTop = function() {
  $("html, body").animate({
    scrollTop: this.root.offset().top - 50
  }, 1000)
}

const scrollToError = function() {
  $("html, body").animate({
    scrollTop: this.root.find(".has-error:first").offset().top - 100
  }, 1000)
}

export { cacheButtonContent, enableButtons, disableButtons, scrollToTop, scrollToError }
