import Widget           from "lib/widget"
import AjaxForm         from "servos/ajaxForm"
import Swal from 'sweetalert2'

class inspectionLookupsForm extends Widget {
  bind() {
    bindActionButtons.call(this)
  }
}

let bindActionButtons = function() {
  // Cancel
  this.root.on('click', '.modal-footer .btn-cancel', (e) => {
    e.preventDefault();
    $('#inspection-lookup').modal('hide');
  })

  // Submit
  this.root.on('click', '.modal-footer .btn-submit', (e) => {
    e.preventDefault();
    submitForm.call(this);
  })
}

let submitForm = function() {
  let form = this.root.find('form')

  if (!form[0].checkValidity()) {
    form[0].classList.add('was-validated');
    return;
  }

  $.ajax({
    url: form.attr('action'),
    method: form.attr('method'),
    dataType: "json",
    data: form.serializeArray()
  }).then((response) => {
    if (response.valid) {
      Swal.fire({
        title: 'Success!',
        text: response.message,
        type: 'success',
        confirmButtonText: 'Ok'
      }).then(() => {
        $('#inspection-lookup').modal('hide');
        location.reload();
      })
    }
    else {
      Swal.fire({
        title: 'Error!',
        text: response.message,
        type: 'error',
        confirmButtonText: 'Ok'
      })
    }
  })
}

export default inspectionLookupsForm;
