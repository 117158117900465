import Widget           from "lib/widget"

class DownloadVirReport extends Widget {

  bind() {
    this.button = this.root.find('.vir-report-btn')
    this.virReport = gon.current_inspection.vir_report
    this.toggleButton()

    bindBtn.call(this);
  }
  
  generateReportRequest() {
    return $.ajax({
      url: gon.current_inspection.urls.vir_report,
      method: "POST",
      dataType: "json"
    })
  }

  getVirReport() {
    return $.ajax({
      url: gon.current_inspection.urls.vir_report,
      method: "GET",
      dataType: "json"
    })
  }
  
  toggleButton() {
    if (this.virReport == null) {
      this.button.toggleClass('disabled', false);
      this.button.prop('disabled', false)
    } else {
      this.button.html(this.virReport.generating ? 'Printing <i class="fa fa-spin fa-spinner"/>' : 'Print Report')
      this.button.toggleClass('disabled', this.virReport.generating);
      this.button.prop('disabled', this.virReport.generating)
    }
  }  


}

let bindBtn = function() {
  this.button.on('click', (e) => {
    this.generateReportRequest().done((response) => {
      // console.log("GENERATED")
      // console.log(response)

      this.virReport = response.vir_report;
      this.toggleButton();
      watchVirReport.call(this)
    })
  })
}

let watchVirReport = function() {
  this.interval = setInterval(() => {
    checkVirReport.call(this)
  }, 1000)
}

let stopWatch = function() {
  clearInterval(this.interval)
}

let checkVirReport = function() {
  this.getVirReport().done((response) => {
    this.virReport = response.vir_report
    this.toggleButton();

    if (this.virReport.ready) {
      stopWatch.call(this)
      window.open(this.virReport.url,'_blank');
      // console.log("DONE")
    }

  })
}


export default DownloadVirReport;
