import Widget               from "lib/widget"
import AjaxForm             from "servos/ajaxForm"
import inspectionLookupsForm from "widgets/lookups/inspection_lookups_form"

class inspectionLookup extends Widget {
  bind() {
    bindCreateLookupButton.call(this);
    bindEditLookupButton.call(this);
  }
}

let bindCreateLookupButton = function() {
  this.root.on('click', "[data-action='create-inspection-lookup']", (e) => {

    $.ajax({
      url: $(e.target).data('url'),
      method: "GET",
      dataType: "json"
    }).done((response) => {
      $('#modal-container').html(response.html)
      $('#inspection-lookup.modal').modal('show')

      new inspectionLookupsForm($('#modal-container'))
    })
  })
}

let bindEditLookupButton = function() {
  this.root.on('click', "[data-action='edit-inspection-lookup']", (e) => {
    $.ajax({
      url: $(e.target).data('url'),
      method: "GET",
      dataType: "json"
    }).done((response) => {
      $('#modal-container').html(response.html)
      $('#inspection-lookup.modal').modal('show')

      new inspectionLookupsForm($('#modal-container'))
    })
  })
}

export default inspectionLookup;
