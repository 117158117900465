import BaseController   from "controllers/accounts/base"
import priceMatrix      from "widgets/price_matrices/price_matrix"
import priceMatrixSearchWidget from "widgets/price_matrices/priceMatrixSearchWidget";
import {Account} from "../../../models/account";

class Controller extends BaseController {
  call() {
    super.call();

    new priceMatrix(this.root)
    bindSearchPriceMatrices.call(this);
  }
}

let bindSearchPriceMatrices = function() {
  let elem = this.root.find('#price-matrices');
  this.priceMatrixSearch = new priceMatrixSearchWidget(elem);
  this.priceMatrixSearch.mergeAndSetState({baseUrl: '/accounts/' + Account.currentAccountName().toLowerCase() + '/price_matrices'});
}

export default Controller;
