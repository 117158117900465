import BaseController         from "controllers/accounts/base";
import {RestClient}           from "api/rest_client";

import vehicleMovements       from "widgets/movements/vehicle_movements";
import vehicleMovement        from "widgets/movements/vehicle_movement";
import DocumentForm           from "widgets/vehicle/document_form"
import Url from "../../../../servos/url";

class Controller extends BaseController {
  call() {
    super.call();

    new vehicleMovements(this.root);
    new vehicleMovement(this.root);
    new DocumentForm(this.root.find('.document-form-container'))
    setActiveTab.call(this);
    bindCurrentMileageUpdate.call(this);
    bindCurrentReserveUpdate.call(this);
    bindHistoryModal.call(this);

    $("select").select2({placeholder: 'Any', allowClear: false});

    $("#save-internal-status-button").click((e) => {
      this.updateVehicleInternalStatus($("#internal-status-selector").val(), e.currentTarget)
    });

    this.bindCreateNoteModal();

    this.updateEnabilityOfSaveChangesButton();
    $("#new-note-text").on('input',() => {
      this.updateEnabilityOfSaveChangesButton();
    })
  }

  bindCreateNoteModal() {
    $("#new-note-section").hide();
    $("#save-changes-button").hide();
    $("#add-note-close").hide();

    $("#add-note-button").click(() => {
      $("#add-note-button").hide();
      $("#save-changes-button").show();
      $("#add-note-close").show();
      $("#new-note-section").show();
    });

    $("#add-note-close").click(() => {
      $("#add-note-button").show();
      $("#save-changes-button").hide();
      $("#add-note-close").hide();
      $("#new-note-section").hide();
    })

    $("#save-changes-button").click(() => {
      if (!$("#save-changes-button").hasClass('disabled')) {
        this.createNote($("#vehicle-notes-modal").data().vehicleId, $("#vehicle-notes-modal").data().userId, $("#new-note-text").val());
        $("#save-changes-button").addClass('disabled');
      }
    });
  }

  updateEnabilityOfSaveChangesButton() {
    if ($("#new-note-text").val().trim() === '') {
      $("#save-changes-button").addClass('disabled')
    } else {
      $("#save-changes-button").removeClass('disabled')
    }
  }

  startSaveSpinner(elem) {
    $(elem).attr('class', 'fa fa-spinner fa-spin')
  }

  stopSaveSpinner(elem) {
    $(elem).attr('class', 'fa fa-save')
  }

  updateVehicleInternalStatus(internalStatus, target) {
    let internalStatusPath = "vehicles/" + $("#vehicle-info-section").data().vehicleId + "/details/update_internal_status"

    // StartSpinner
    this.startSaveSpinner($(target).find('div'))

    RestClient.execute({method: 'put', path: internalStatusPath, payload: {internal_status: internalStatus}})
      .success((statusCode, responsePayload) => {
        this.stopSaveSpinner($(target).find('div'))
        this.root.find(".internal-status-message").html(`<div class='alert alert-sm alert-success'>Status Successfully Updated</div>`).show();
        this.root.find('#api-payload-error-message').html(responsePayload.api_payload_message)
        setTimeout(() => { this.root.find('.internal-status-message .alert').fadeOut(); }, 3000);
      })
      .failure((statusCode, responsePayload) => {
        this.stopSaveSpinner($(target).find('div'))
        let messageContainer = this.root.find('.ams-sale-channel-message')

        if (responsePayload.message.includes('itemNo')) {
          messageContainer.html(responsePayload.message)
          messageContainer.toggleClass('hidden', false)
        } else {
          messageContainer.html('')
          messageContainer.toggleClass('hidden', true)
        }

        this.root.find('#internal-status-selector').val(responsePayload.internal_status).trigger('change')
        this.root.find(".internal-status-message").html(`<div class='alert alert-sm alert-danger'>` + responsePayload.message + `</div>`).show();
        this.root.find('#api-payload-error-message').html(responsePayload.api_payload_message)
        setTimeout(() => { this.root.find('.internal-status-message .alert').fadeOut(); }, 5000);
      });
  }

  createNote(vehicleId, userId, noteText) {
    RestClient.execute({method: 'post', path: "vehicles/" + vehicleId + "/notes", payload: {user_id: userId, note_text: noteText}})
      .success(function(statusCode, responsePayload) {
        $("#save-changes-button").removeClass('disabled');
        $("#add-note-button").show();
        $("#save-changes-button").hide();
        $("#new-note-section").hide();
        location.reload();
      })
      .failure(function(statusCode, responsePayload) {
        console.log(statusCode, responsePayload);
      });
  }
}

let bindHistoryModal = function() {
  this.root.find('.btn-history').on('click', (e) => {
    let url = $(e.currentTarget).data('url')

    $.ajax({
      url: url,
      method: 'get'
    }).done((response) => {
      this.root.find('#modal-container').html(response.html)
      this.root.find('#modal-container .modal').modal('show')
    })
  })
}

let bindCurrentMileageUpdate = function() {
  this.root.find('.btn-mileage').on('click', (e) => {
    let mileage = this.root.find("input[name='vehicle[current_mileage]']").val();

    let saveElem = $(e.currentTarget).find('div')
    this.startSaveSpinner(saveElem)

    $.ajax({
      url: gon.vehicle.updateMileageUrl,
      method: "put",
      data: {
        vehicle: {
          current_mileage: mileage
        }
      },
      dataType: "Json"
    }).done((response) => {
      this.stopSaveSpinner(saveElem)
      this.root.find('.mileage-update-messsage').html(`<div class='alert alert-sm alert-success'>${response.message}</div>`);
      setTimeout(() => { this.root.find('.mileage-update-messsage .alert').fadeOut(); }, 3000);

    }).fail(( jqXHR, textStatus, errorThrown ) => {
      this.stopSaveSpinner(saveElem)
      this.root.find('.mileage-update-messsage').html(`<div class='alert alert-sm alert-danger'>${jqXHR.responseJSON.message}</div>`).show();
      setTimeout(() => { this.root.find('.mileage-update-messsage .alert').fadeOut(); }, 3000);
    })
  })
}

let bindCurrentReserveUpdate = function() {
  this.root.find('.btn-reserve').on('click', (e) => {
    let reserve = this.root.find("input[name='vehicle[reserve]']").val();

    let saveElem = $(e.currentTarget).find('div')
    this.startSaveSpinner(saveElem)

    $.ajax({
      url: gon.vehicle.updateReserveUrl,
      method: "put",
      data: {
        vehicle: {
          reserve: reserve
        }
      },
      dataType: "Json"
    }).done((response) => {
      this.stopSaveSpinner(saveElem)
      this.root.find('.reserve-update-messsage').html(`<div class='alert alert-sm alert-success'>${response.message}</div>`);
      this.root.find('#api-payload-error-message').html(responsePayload.api_payload_message)
      setTimeout(() => { this.root.find('.reserve-update-messsage .alert').fadeOut(); }, 3000);

    }).fail(( jqXHR, textStatus, errorThrown ) => {
      this.stopSaveSpinner(saveElem)
      this.root.find('#vehicle_reserve').val(jqXHR.responseJSON.reserve)
      this.root.find('.reserve-update-messsage').html(`<div class='alert alert-sm alert-danger'>${jqXHR.responseJSON.message}</div>`).show();
      this.root.find('#api-payload-error-message').html(responsePayload.api_payload_message)
      setTimeout(() => { this.root.find('.reserve-update-messsage .alert').fadeOut(); }, 3000);
    })
  })
}

let setActiveTab = function() {
  let tab = Url.getQueryParamValue('tab');

  if (tab != null) {
    $('ul.nav a[href="#' + tab + '"]').tab('show')
  }
}

export default Controller;
