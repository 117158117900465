import BaseController from "controllers/accounts/base";
import Swal from "sweetalert2";
class Controller extends BaseController {
  call() {
    super.call(this);
    bindDestroyLink.call(this);
  }
}

let bindDestroyLink = function () {
  this.root.find(".btn-destroy").on("click", (e) => {
    e.preventDefault();

    let url = $(e.currentTarget).data("url");

    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this imaginary file!",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        $.ajax({
          url: url,
          method: "DELETE",
          dataType: "json",
          success: (response) => {},
        });
        Swal.fire(
          "Deleted!",
          "Your imaginary file has been deleted.",
          "success"
        ).then((result) => {
          if (result.value) {
            window.location.reload();
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your imaginary file is safe :)", "error");
      }
    });

    // swal({
    //   title:  "myTitle",
    //   html:   "myHtml",
    //   type:   "info",
    //   showCancelButton: true,
    //   confirmButtonText: "Ok"
    // }).then((Confirm) => {
    //   $.ajax({
    //     url: url,
    //     method: 'DELETE',
    //     dataType: 'json',
    //     success: (response) => {
    //       window.location.reload();
    //     }
    //   });
    // }).catch(() => {
    //   // We don't need to process any dismissals
    // })
  });
};

export default Controller;
