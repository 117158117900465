import Eventful from "mixins/eventful"
import { cacheButtonContent, enableButtons, disableButtons } from "servos/formHelpers"
import FormLoader from 'mixins/form'

class AjaxForm {
  constructor(form, config = {}, customData = {}) {
    Eventful.call(this)

    this.buttons = form.find("input[type='submit'], button[type='submit']")

    // Cache original content for when we come out of disabled
    cacheButtonContent(this.buttons)

    this.config = config
    this.form = form
    this.customData = customData

    this.bindForm(form)
  }

  bindForm(form) {
    form.submit((e) => {
      e.preventDefault()
      console.log("prevent default")
      if (!this.config.dontDisable) {
        disableButtons(this.buttons)
      }

      // Allow us to do work before performing ajax
      const promise = new Promise((resolve, reject) => {
        if (this.config.beforeAjax) {
          // Perform other work and wait for promise to be resolved
          this.config.beforeAjax({resolve: resolve,
                                  reject: reject})
        } else {
          // Just resolve so we can continue ajaxing
          resolve()
        }
      })

      // Once promise is resolved, do the ajax call
      promise.then(this.doAjax.bind(this))

      // In case of error, do nothing but enable buttons
      promise.catch(() => {
        enableButtons(this.buttons)
      })

    })
  }

  formData() {
    let data = this.form.serialize()
    _.forIn(this.customData, (value, key) => { data = `${data}&${key}=${value}` })
    return data
  }

  doAjax(dataType="json", customAction=null) {
    $.ajax({
      method: this.config["method"] || this.form.prop("method" || "POST"),
      url:    customAction || this.config["action"] || this.form.prop("action"),
      data:   this.formData(),
      dataType: dataType,
      success:  success.bind(this),
      error:    error.bind(this),
      complete: complete.bind(this)
    })
  }
}

const success = function(data, status, jqXHR) {
  this.emit("success", {
    data: data,
    status: status,
    jqXHR: jqXHR
  })
}

const complete = function(jqXHR, status) {
  this.emit("complete", {
    jqXHR: jqXHR,
    status: status
  })
  if (!this.config.dontEnable) {
    enableButtons(this.buttons)
  }
}

const error = function(jqXHR, status, errorThrown) {
  if (jqXHR.status == 422) {
    this.emit("422", {
      jqXHR: jqXHR,
      status: status,
      errorThrown: errorThrown
    })
  }
  this.emit("error", {
    jqXHR: jqXHR,
    status: status,
    errorThrown: errorThrown
  })
}

export default AjaxForm
