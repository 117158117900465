import Widget           from "lib/widget"
import AjaxForm         from 'servos/ajaxForm'

class EditInspection extends Widget {
  bind() {
    bindForm.call(this);
    showInputValue.call(this);
    toggleAllowanceInputs.call(this);
  }

  submit() {
    this.root.submit();
    this.root.find('.submit-btn').addClass('disabled')
    this.root.find('.submit-btn').prop('disabled', 'disabled')
  }
}

let bindForm = function() {
  this.ajaxForm = new AjaxForm(this.root);
  this.ajaxForm.on("success",  success.bind(this));
  this.ajaxForm.on("error",    error.bind(this));
}

let success = function(response) {
  $('#edit-inspection-modal').modal('hide');
  swal({
    title: "Successfully Updated!"
  })
  window.location = response.redirectUrl;
  window.location.reload(true);
}

let error = function(response) {
  this.replaceHtml(response.jqXHR.responseJSON.html)
}

let toggleAllowanceInputs = function(){
  $('select').on('change', () => {
    showInputValue.call(this);
  })
}

let showInputValue = function(){
  let selected = $('.selected-option option:selected').val()

  if(selected === 'buffer'){
    $('#buffer').removeClass('d-none')
    $('#waiver').addClass('d-none')
    $("#inspection_waiver").val(0)
  } else if(selected === 'waiver') {
    $('#waiver').removeClass('d-none')
    $('#buffer').addClass('d-none')
    $("#inspection_buffer").val(0)
  } else {
    $('#buffer').addClass('d-none')
    $('#waiver').addClass('d-none')
    $("#inspection_buffer").val(0)
    $("#inspection_waiver").val(0)
  }
}

export default EditInspection;
