import Widget               from "lib/widget"
import AjaxForm             from "servos/ajaxForm"
import vehicleMovementsForm from "widgets/movements/vehicle_movements_form"

class vehicleMovements extends Widget {
  bind() {

    bindCreateMovementButtons.call(this)
  }
}

let bindCreateMovementButtons = function() {
  this.root.on('click', "[data-action='create-movement']", (e) => {
    openMovementModal.call(this, $(e.target))
  })

  this.root.on('click', "[data-action='update-movement']", (e) => {
    openMovementModal.call(this, $($(e.target).closest('tr')[0]))
  })
}

let openMovementModal = function(elem) {
  self.movements_form = new vehicleMovementsForm($('#modal-container'))

  $.ajax({
    url: $(elem).data('url'),
    method: "GET",
    dataType: "json"
  }).done((response) => {
    self.movements_form.replaceHtml(`<div id="modal-container">${response.html}</div>`)
    $('#vehicle-movement.modal').modal('show')
  })
}

export default vehicleMovements;
