import Widget           from "lib/widget"
import AjaxForm         from "servos/ajaxForm"

class Keys extends Widget {
  bind() {
    bindKeysForm.call(this)
  }
}

let bindKeysForm = function() {
  const form = this.root.find("form")

  if (form.length > 0) {
    const ajaxForm = new AjaxForm(form)
    ajaxForm.on("success",  keys_success.bind(this, form))
    ajaxForm.on("error",    keys_error.bind(this, form))
    bindCancelBtn.call(this)
  } else {
    this.root.find('.keys-edit').on('click', (e) => {
      e.preventDefault();
      let url = e.currentTarget.href;

      $.ajax({
        url: url,
        method: "GET",
        dataType: "json"
      }).done((response) => {
        keysEditForm.call(this, response)
      })

    })
  }
}

let bindCancelBtn = function() {
  this.root.find('.btn-cancel').on('click', (e) => {
    e.preventDefault();
    let url = e.currentTarget.href;
    $.ajax({
      url: url,
      method: "GET",
      dataType: "json"
    }).done((response) => {
      this.root.html(response.html)
      bindKeysForm.call(this)
    })    
  })
}

let keysEditForm = function(response) {
  this.root.html(response.html);
  bindKeysForm.call(this);
}

let keys_success = function(form, response) {
  this.root.html(response.data.html)
  bindKeysForm.call(this)
}

let keys_error = function(form, response) {
  this.root.html(response.jqXHR.responseJSON.html)
  bindKeysForm.call(this)
}

export default Keys;
