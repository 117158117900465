import $ from 'jquery';

import 'bootstrap-datepicker';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker';

export default function(controller) {

  $("[data-toggle='datepicker-default']").datepicker({
    format: 'dd/mm/yyyy',
    orientation: 'bottom'
  }).on('changeDate', (e) => {
    controller.emit('datepicker-changed', this)
  });

  $("[data-toggle='datepicker-not-in-past']").datepicker({
    format: 'dd/mm/yyyy',
    orientation: 'bottom',
    startDate: new Date(Date.now()).toLocaleString()
  }).on('changeDate', (e) => {
    controller.emit('datepicker-changed', this)
  });

  $("[data-toggle='datepicker-not-in-future']").datepicker({
    format: 'dd/mm/yyyy',
    orientation: 'bottom',
    endDate: new Date(Date.now()).toLocaleString()
  }).on('changeDate', (e) => {
    controller.emit('datepicker-changed', this)
  });

};
