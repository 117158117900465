import BaseController from "controllers/base";

class Controller extends BaseController {
  call() {
    super.call();
    // this.current_account = gon.current_account;
  }
}

export default Controller;
