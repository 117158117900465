import Widget           from "lib/widget"
import AjaxFileForm     from 'servos/ajaxFileForm'

class Image extends Widget {
  bind() {
    let formElem = this.root.find('form')

    let ajaxForm = new AjaxFileForm(formElem)

    ajaxForm.on('success', handle_success.bind(this));
    ajaxForm.on('error', handle_error.bind(this));

    this.root.find('.attachment-field').on('change', (e) => {
      this.root.find('.icon').html('<span class="fa fa-spinner fa-spin"></span>')
      formElem.submit()
    })
  }
}

let handle_success = function(response) {
  window.location = response.data.redirectUrl;
  window.location.reload(true);
}

let handle_error = function(response) {
  console.log(response.jqXHR.responseJSON.errors)
}

export default Image;
