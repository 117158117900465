import Widget           from "lib/widget"
import AjaxForm         from "servos/ajaxForm"
import vehicleMovementLocation from "widgets/movements/vehicle_movement_location"
import vehicleMovement  from "widgets/movements/vehicle_movement"
import Select2Widget from "widgets/select2Widget"
import Swal from 'sweetalert2'

class vehicleMovementsForm extends Widget {
  bind() {
    bindActionButtons.call(this)
    bindLocationSections.call(this)
    bindVehicleMovements.call(this)
    updateVehicleMovementAddress.call(this);

    this.pickup_depot_transfer_field = new Select2Widget(".pickup-depot-address")

    this.delivery_depot_transfer_field = new Select2Widget(".delivery-depot-address")

    this.form_container = this.root.find('.form-container')
  }
}

let bindActionButtons = function() {

  this.root.on('cocoon:after-insert', (e, added_item) => {
    new vehicleMovement($(added_item))
  })

  // Cancel
  this.root.on('click', '.modal-footer .btn-cancel', (e) => {
    this.root.find('.modal').modal('hide')
  })

  // Submit
  // this.root.on('click', '.modal-footer .btn-submit', (e) => {
  //   submitForm.call(this)
  // })
  this.root.on('click', '.modal-footer .btn-submit', (e) => {
    e.preventDefault(); // Prevent the default form submission
    let modalFooter = $(e.target).closest('.modal-footer'); // Find the closest .modal-footer
    submitForm.call(this, modalFooter); // Call submitForm with the context of this and pass the modalFooter
  });

  // Destroy
  this.root.on('click', '.modal-footer .btn-delete', (e) => {
    let url = $(e.target).data('url')

    Swal.fire({
      title:  `Wait!`,
      html:   `Are you sure you want to remove this movement?`,
      type:   "info",
      showCancelButton: true,
      confirmButtonText: `Ok`
    }).then((Confirm) => {
      $.ajax({
        url: url,
        method: 'DELETE',
        dataType: 'json',
        success: (response) => {
          $(location).attr('href', response.redirect_url);
        }
      });
    }).catch(() => {
      // We don't need to process any dismissals
    })

    // swal({
    //   title:  `Wait!`,
    //   html:   `Are you sure you want to remove this movement?`,
    //   type:   "info",
    //   showCancelButton: true,
    //   confirmButtonText: `Ok`
    // }).then((Confirm) => {
    //   $.ajax({
    //     url: url,
    //     method: 'DELETE',
    //     dataType: 'json',
    //     success: (response) => {
    //       $(location).attr('href', response.redirect_url);
    //     }
    //   });
    // }).catch(() => {
    //   // We don't need to process any dismissals
    // })
  })
}

let bindVehicleMovements = function() {
  // bindVehicleMovements
  $.each(this.root.find('.vehicle-movement-item tr'), (index, value) => {
    new vehicleMovement($(value))
  });
}

let bindLocationSections = function() {
  $.each(this.root.find('.address-block'), (index, value) => {
    new vehicleMovementLocation($(value))
  });
}

let submitForm = function(modalFooter) {
  let form = modalFooter.closest('.modal').find('form').first();
  if (!form[0].checkValidity()) {
    form[0].classList.add('was-validated');
    return;
  }

  $.ajax({
    url: form.attr('action'),
    method: form.attr('method'),
    dataType: "json",
    data: form.serializeArray()
  }).done((response) => {

    Swal.fire({
      title: 'Success!',
      text: response.message,
      type: 'success',
      confirmButtonText: 'Ok'
    }).then(() => {
      this.root.find('.modal').modal('hide')
      location.reload();
    })


  //   swal({
  //     title:  `Success`,
  //     html:   `Movement Saved`,
  //     type:   "success",
  //     showCancelButton: true,
  //     confirmButtonText: `Ok`
  //   }).then((Confirm) => {
  //     $(location).attr('href', response.redirect_url);
  //   })

  // }).fail((jqXHR, textStatus) => {

  //   swal({
  //     title: "Error",
  //     text: 'Please review form errors',
  //     type: 'error'
  //   });

  //   this.renderPartial(this.form_container, jqXHR.responseJSON.html)
  //   bindLocationSections.call(this);
  //   updateVehicleMovementAddress.call(this);
  })
}

let updateVehicleMovementAddress = function(){

  // movement pickup address
  this.root.find("[name='collection-location-pickup']").on("change", (e) => {
    togglePickup.call(this);
  })
  togglePickup.call(this);

  // movement delivery address
  this.root.find("[name='collection-location-delivery']").on("change", (e) => {
    toggleDelivery.call(this);
  })
  toggleDelivery.call(this);
}

// movement pickup address
let togglePickup = function(){
  let chosen = $("[name='collection-location-pickup']:checked").val();

  if (chosen == 'saved') {
    $('.pickup-other-destroy').val(true)
  } else {
    $('.pickup-other-destroy').val(false)
    if (this.pickup_depot_transfer_field !== undefined) {
      this.pickup_depot_transfer_field.clear()
    }
  }
}

// movement delivery address
let toggleDelivery = function(){
  let chosen = $("[name='collection-location-delivery']:checked").val();

  if (chosen == 'saved') {
    $('.delivery-other-destroy').val(true)
  } else {
    $('.delivery-other-destroy').val(false)
    if (this.delivery_depot_transfer_field !== undefined) {
      this.delivery_depot_transfer_field.clear()
    }
  }
}

export default vehicleMovementsForm;
