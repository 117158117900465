import Widget           from "lib/widget"
import AjaxForm         from "servos/ajaxFileForm"

class DocumentForm extends Widget {
  bind() {
    bindDocumentForm.call(this)
  }
}

let filterDiv = "<div class='loading'><i class='fa fa-spinner fa-pulse'></i></div>"

let bindDocumentForm = function() {
  console.log('bindDocumentForm')
  const form = this.root.find("form")
  const ajaxForm = new AjaxForm(form, {beforeAjax: beforeSend.bind(this)})

  ajaxForm.on("success",  document_success.bind(this, form))
  ajaxForm.on("error",    document_error.bind(this, form))
}

let beforeSend = function(callbacks) {
  $('#vehicle-documents-modal').append(filterDiv)
  callbacks.resolve()
}

// here we will want to refresh the page
let document_success = function(form, response) {
  $('#vehicle-documents-modal').modal('hide');
  swal({
    title: `Document Successfully Added!`,
    type: 'success'
  })

  window.location = response.data.redirectUrl
}

let document_error = function(form, response) {
  this.root.html(response.jqXHR.responseJSON.html)
  bindDocumentForm.call(this)
}

export default DocumentForm;
