import BaseController            from "controllers/accounts/base"
import KeysWidget                from "widgets/inspection/keys"
import TyresWidget               from "widgets/inspection/tyres"
import AccessoriesWidget         from "widgets/inspection/accessories"
import ImagesUploadWidget        from "widgets/inspection/images"
import DamageItemsWidget         from "widgets/inspection/damageItems"
import EditInspectionWidget      from "widgets/inspection/editInspection"
import InspectionDetailsWidget   from "widgets/inspection/inspectionDetails"
import ImagesDownloadWidget      from "widgets/inspection/imagesDownload"
import DownloadVirReport         from "widgets/inspection/downloadVirReport"

class Controller extends BaseController {
  call() {
    super.call();
    new KeysWidget(this.root.find('.inspection-keys-container'))
    new TyresWidget(this.root.find('.inspection-tyres-container'))
    new AccessoriesWidget(this.root.find('.inspection-accessories-container'))
    new ImagesUploadWidget(this.root.find('.inspection-images-container'))
    new InspectionDetailsWidget(this.root.find('.toggle-allowance'))
    new ImagesDownloadWidget(this.root)
    new DownloadVirReport(this.root)

    bindRequestEditButton.call(this);
    bindRequestMoreImagesButton.call(this);
    loadDamageItemsIndex.call(this);
  }
}

let loadDamageItemsIndex = function() {
  $.ajax({
    url: gon.current_inspection.urls.damage_items,
    method: 'GET',
    dataType: 'json'
  }).done((response) => {
    this.root.find('.inspection-damage-items-container').html(response.html);
    new DamageItemsWidget(this.root.find('.inspection-damage-items-container'))
    $('[data-toggle="popover"]').popover()
  })
}

let bindRequestEditButton = function() {
  this.root.on('click', '.btn-inspection-edit', (e) => {
    $.ajax({
      url: gon.current_inspection.urls.edit,
      method: 'GET',
      dataType: 'json'
    }).done((response) => {
      this.root.find('#modal-container').html(response.html);
      this.root.find('#modal-container .modal').modal('show')
      new EditInspectionWidget(this.root.find('#edit-inspection-modal form'))
    })
  })
}

let bindRequestMoreImagesButton = function() {
  this.root.on('click', '.btn-request-images-modal', (e) => {
    $.ajax({
      url: gon.current_inspection.urls.request_more_images,
      method: 'GET',
      dataType: 'json'
    }).done((response) => {
      this.root.find('#modal-container').html(response.html);
      this.root.find('#modal-container .modal').modal('show');
  
      if (!$("#email-address").html().trim()) {
        $("#send-email-button").attr("disabled", "disabled");
        $("#send-email-button").attr("value", "NO DEPOT EMAIL PRESENT");
      }
    })
  })
}

export default Controller;
