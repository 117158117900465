import Widget from "lib/widget";
import AjaxForm from "servos/ajaxForm";
import NewImageWidget from "widgets/inspection/damage_item/newImage";
import ImageWidget from "widgets/inspection/damage_item/image";
import Swal from "sweetalert2";

class Item extends Widget {
  bind() {
    bindImageForm.call(this);
    bindEditBtn.call(this);
    bindDeleteBtn.call(this);
    bindImages.call(this);
  }
}

let bindImageForm = function () {
  let imageForm = this.root.find(".image-form-container");

  new NewImageWidget(imageForm);
};

let bindImages = function () {
  _.each(this.root.find(".inspection-image-item"), (image) => {
    new ImageWidget($(image));
  });
};

let bindDeleteBtn = function () {
  this.root.find(".delete-item-btn").on("click", (e) => {
    e.preventDefault();

    let url = e.currentTarget.href;

    Swal.fire({
      title: "Do you want to remove this item?",
      text: "You cannot undo this action",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        $.ajax({
          url: url,
          method: "put",
          dataType: "json",
        }).done((response) => {
          console.log(response);
        });
        // Swal.fire(
        //   "Deleted!",
        //   "Your item has been deleted.",
        //   "success"
        // ).then((result) => {
        //   if (result.value) {
        //     window.location.reload();
        //   }
        // });
      }
    });

    // swal({
    //   title:  `${I18n.t('damage_items.swal.destroy.title')}`,
    //   html:   `${I18n.t('damage_items.swal.destroy.description')}`,
    //   type:   "info",
    //   showCancelButton: true,
    //   confirmButtonText: `${I18n.t('damage_items.swal.destroy.action')}`
    // }).then((Confirm) => {
    //   $.ajax({
    //     url: url,
    //     method: 'PUT',
    //     dataType: 'json',
    //     success: (response) => {
    //     swal({
    //       title: `${I18n.t('damage_items.swal.destroy.success_title')}`,
    //       type: 'success'
    //     })
    //     window.location = response.redirectUrl;
    //     window.location.reload(true);
    //     }
    //   });
    // }).catch(() => {
    //   // We don't need to process any dismissals
    // })
  });
};

let bindEditBtn = function () {
  this.root.find(".btn-edit").on("click", (e) => {
    e.preventDefault();
    let url = e.currentTarget.href;

    $.ajax({
      url: url,
      method: "GET",
      dataType: "json",
    }).done((response) => {
      let modalContainer = $("#modal-container");
      modalContainer.html(response.html);

      this.modal = modalContainer.find(".modal");
      this.modal.modal("show");

      this.modal.on("change", ".price_overwrite_input", (e) => {
        const checkboxChecked = e.target.checked;
        const retail_price = this.modal.find(".retail-price-input");
        if (!checkboxChecked) {
          retail_price.val("");
        }
      });

      this.modal.find(".submit-btn").on("click", (e) => {
        this.modal.find("form").submit();
      });

      bindForm.call(this);
    });
  });
};

let bindForm = function () {
  this.form = this.modal.find("form");
  this.ajaxForm = new AjaxForm(this.form);
  this.ajaxForm.on("success", success.bind(this));
  this.ajaxForm.on("error", error.bind(this));

  this.form.find(".location-select").change((e) => {
    requestAssignedForm.call(this, "location_selected");
  });

  this.form.find(".component-select").change((e) => {
    requestAssignedForm.call(this, "component_selected");
  });

  this.form.find(".fault-select").change((e) => {
    requestAssignedForm.call(this, "fault_selected");
  });

  this.form.find(".repair-action-select").change((e) => {
    requestAssignedForm.call(this, "repair_action_selected");
  });

  this.form.find(".hours-input").on("change", (e) => {
    requestAssignedForm.call(this, "hours_changed");
  });
};

// assign values to form and re render the partial with new values.
// used to dynamically update select options
let requestAssignedForm = function (event) {
  let data = _.merge(objectifyForm(this.form), { event: event });
  let assignUrl = this.form.data().assignurl;

  this.modal
    .find("#form-container")
    .html(
      '<div class="spinner-container"><span class="fa fa-spinner fa-4x fa-spin"></span></div>'
    );

  $.ajax({
    url: assignUrl,
    method: "get",
    data: data,
    dataType: "json",
  }).done((response) => {
    this.modal.find("#form-container").html(response.html);
    bindForm.call(this);
  });
};

//serialize data function
let objectifyForm = function (form) {
  let formArray = form.serializeArray();

  var returnArray = {};
  for (var i = 0; i < formArray.length; i++) {
    returnArray[formArray[i]["name"]] = formArray[i]["value"];
  }
  return returnArray;
};

let success = function (response) {
  this.modal.modal("hide");
  Swal.fire({
    title: `${I18n.t("damage_items.swal.update.success_title")}`,
    type: "success",
  });
  // swal({
  //   title: `${I18n.t('damage_items.swal.update.success_title')}`,
  //   type: 'success'
  // })
  window.location = response.redirectUrl;
  window.location.reload(true);
};

let error = function (response) {
  this.modal.find("#form-container").html(response.jqXHR.responseJSON.html);
  bindForm.call(this);
};

export default Item;
