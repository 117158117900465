// Can put common controller stuff in here :D
// import Raven from "raven-js";

import * as initializers0 from '../initializers/alerts.js'; import * as initializers1 from '../initializers/delay.js'; import * as initializers2 from '../initializers/input_components/bootstrap.js'; import * as initializers3 from '../initializers/input_components/colorpicker.js'; import * as initializers4 from '../initializers/input_components/datepicker.js'; import * as initializers5 from '../initializers/input_components/input_mask.js'; import * as initializers6 from '../initializers/input_components/jquery-sortable.js'; import * as initializers7 from '../initializers/input_components/radio_buttons.js'; import * as initializers8 from '../initializers/input_components/select2.js'; import * as initializers9 from '../initializers/input_components/switchery.js'; import * as initializers10 from '../initializers/sweetalert.js'; import * as initializers11 from '../initializers/tooltips.js'; let initializers = [initializers0, initializers1, initializers2, initializers3, initializers4, initializers5, initializers6, initializers7, initializers8, initializers9, initializers10, initializers11];

let runInitializers = function () {
  if (initializers == undefined) { return };

  initializers.forEach((initializer) => {
    return initializer.default(this);
  });
};

let Controller = function () {
  this.init = () => {
    try {
      runInitializers.call(this);
      this.call();
    } catch(e) {
      if (gon.env == "development") {
        console.error(e.stack)
      } else {
        // Raven.captureException(e);
      }
    }
  };
};

export default Controller;
