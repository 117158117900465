import BaseController         from "../base";
import InspectableAccounts    from 'widgets/accounts/account_users/inspectableAccounts';

class Controller extends BaseController {
  call() {
    super.call(this);
    bindInspectableAccounts.call(this)
  }
}

let bindInspectableAccounts = function() {
  new InspectableAccounts(this.root)
}

export default Controller;
