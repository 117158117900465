import Widget from "lib/widget";
import DamageItem from "widgets/inspection/damageItem";
import NewDamageItem from "widgets/inspection/newDamageItem";
import Swal from "sweetalert2";

class Items extends Widget {
  bind() {
    bindItems.call(this);
    bindStatusUpdate.call(this);
    bindRequestQuoteBtn.call(this);
    bindNewDamageItemButton.call(this);
  }
}

let bindNewDamageItemButton = function () {
  this.root.on("click", ".btn-add-damage", (e) => {
    $.ajax({
      url: gon.current_inspection.urls.new_damage_item,
      method: "GET",
      dataType: "json",
    }).done((response) => {
      this.root.find("#damage-item-modal-container").html(response.html);
      $("#damage-item-modal-container .modal").modal("show");
      bindNewForm.call(this);
    });
  });
};

let bindItems = function () {
  _.each(this.root.find(".damage-item"), (itemElem) => {
    new DamageItem($(itemElem));
  });
};

let bindNewForm = function () {
  let newDamageItem = new NewDamageItem(
    this.root.find("#new-damage-item-modal").find("form")
  );

  this.root.on("click", "#new-damage-item-modal .submit-btn", (e) => {
    newDamageItem.submit();
  });
};

let bindStatusUpdate = function () {
  toggleStatusButtons.call(this, false);
  this.root.find(".select-damage-item").on("change", (e) => {
    if (this.root.find(".select-damage-item:checked").length > 0) {
      toggleStatusButtons.call(this, true);
    } else {
      toggleStatusButtons.call(this, false);
    }
  });
  bindAuthorizeBtn.call(this);
  bindRejectBtn.call(this);
};

let toggleStatusButtons = function (active) {
  this.root.find(".btn-authorize").prop("disabled", !active);
  this.root.find(".btn-authorize").toggleClass("disabled", !active);

  this.root.find(".btn-reject").prop("disabled", !active);
  this.root.find(".btn-reject").toggleClass("disabled", !active);
};

let bindAuthorizeBtn = function () {
  this.root.find(".btn-authorize").on("click", (e) => {
    Swal.fire({
      title: "Do you want to authorize the selected items?",
      text: "",
      type: "info",
      showCancelButton: true,
      confirmButtonText: "Authorize",
    }).then((result) => {
      if (result.value) {
        $.ajax({
          url: gon.current_inspection.urls.authorize_items,
          method: "PUT",
          dataType: "json",
          data: { items_ids: getSelectedItemIds.call(this) },
        }).done((response) => {
          Swal.fire({
            title: "Success!",
            type: "success",
          }).then((result) => {
            if (result.value) {
              window.location = response.redirectUrl;
              window.location.reload(true);
            }
          });
        });
      }
    });
  });
};

let bindRejectBtn = function () {
  this.root.find(".btn-reject").on("click", (e) => {
    Swal.fire({
      title: "Do you want to reject the selected items?",
      text: "",
      type: "info",
      showCancelButton: true,
      confirmButtonText: "Reject",
    }).then((result) => {
      if (result.value) {
        $.ajax({
          url: gon.current_inspection.urls.reject_items,
          method: "PUT",
          dataType: "json",
          data: { items_ids: getSelectedItemIds.call(this) },
        }).done((response) => {
          Swal.fire({
            title: "Success!",
            type: "success",
          }).then((result) => {
            if (result.value) {
              window.location = response.redirectUrl;
              window.location.reload(true);
            }
          });
        });
      }
    });
  });
};

let bindRequestQuoteBtn = function () {
  this.root.find(".request-quote-link").on("click", (e) => {
    e.preventDefault();
    let url = e.currentTarget.href;
    console.log(`request quote ${url}`);

    $.ajax({
      url: url,
      method: "get",
      dataType: "json",
    }).done((response) => {
      $("#modal-container").html(response.html);
      $("#quote-modal").modal();
    });
  });
};

let getSelectedItemIds = function () {
  return _.map(this.root.find(".select-damage-item:checked"), (item) => {
    return item.value;
  });
};

export default Items;
