import $              from "jquery"
import select2        from "select2"
import "select2/dist/css/select2.min.css"
import "select2-bootstrap-theme/dist/select2-bootstrap.min.css"

export default function(controller) {

  $("[data-toggle='select2-multi-fixed']").select2({
    placeholder: '',
    width: '100%',
    minimumInputLength: 1,
    allowClear: true,
    tags: false
  });

  $("[data-toggle='select2-multi']").select2({
    placeholder: '',
    width: '100%',
    minimumInputLength: 1,
    allowClear: true,
    tags: true
  });

  $("[data-toggle='select2-basic']").select2({
    placeholder: '',
    allowClear: true,
    width: '100%',
  }).on('change', (e) => {
    controller.emit('select2-changed', this)
  });

  // use this if you want to use the select prompt as placeholder
  $("[data-toggle='select2-basic-prompt']").select2({
    allowClear: true,
    width: '100%',
  }).on('change', (e) => {
    controller.emit('select2-changed', this)
  });

};
