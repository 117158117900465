import BaseController         from "controllers/accounts/base"
import InternalStatus         from "widgets/accounts/dashboard/internal_status"
import Inspection             from "widgets/accounts/dashboard/inspection"
import DistributionChannel    from "widgets/accounts/dashboard/distribution_channel"

class Controller extends BaseController {
  call() {
    super.call(this);
    bindInternalStatus.call(this)
    bindInspection.call(this)
    bindDistributionChannel.call(this)
  }
}

let bindInternalStatus = function() {
  let url = gon.account.dashboard_urls.internal_status
  let elem = this.root
  this.internalStatus = new InternalStatus(elem, url)
}

let bindInspection = function() {
  let url = gon.account.dashboard_urls.inspection
  let elem = this.root
  this.inspection = new Inspection(elem, url)
}

let bindDistributionChannel = function() {
  let url = gon.account.dashboard_urls.distribution_channel
  let elem = this.root
  this.distribution_channel = new DistributionChannel(elem, url)
}

export default Controller;
