import Widget           from "lib/widget"

class ImageModal extends Widget {

  constructor(url) {
    super()
    this.modal = $('#image-modal.modal')
    this.url = url
  }

  open() {
    let elem = $(`<div class='background-image'></div>`)
    elem.css('backgroundImage', `url(${this.url})`)

    console.log(elem)

    this.modal.find('.modal-body').html(elem)
    this.modal.modal('show');
  }
}

export default ImageModal;
