import BaseController                           from "controllers/base";

import FlashMessages                            from "mixins/flash";
import {displaySuccessFlash, displayErrorFlash} from "servos/ajaxFlash";
import {RestClient}                             from "../../api/rest_client";
import Url from "../../servos/url";

class Controller extends BaseController {
  call() {
    super.call();
    this.current_account = gon.current_account;

    this.initVehiclesSearch();
  }

  initVehiclesSearch() {
    // URLSearchParams doesn't work on IE 11 & several other supposed IE solutions actually don't work on IE
    $("input#search-query").val(Url.getQueryParamValue('search_query'));

    $("#search-button").click(function() {
      let queryParams = $("input#search-query").val() ? '?' + 'search_query=' + $("input#search-query").val() : '';
      window.location.href = RestClient.getUrl('vehicles' + queryParams);
    });
    $('input#search-query').keyup(function(e) {
      if(e.keyCode === 13) {
        let queryParams = $("input#search-query").val() ? '?' + 'search_query=' + $("input#search-query").val() : '';
        window.location.href = RestClient.getUrl('vehicles' + queryParams);
      }
    });
  }

}

export default Controller;
