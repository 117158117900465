import Widget from "lib/widget";
import AjaxForm from 'servos/ajaxForm'

let filterDiv = "<div class='loading'><i class='fa fa-spinner fa-pulse'></i>&nbsp;&nbsp;Filtering...</div>"

let success = function(response, event) {
  $('.loading').fadeOut()
  this.root.find('.filtered-list').html(response.data.listHtml)
  this.emit('updated-list', response.data)
}

let error = function() {
  $('.loading').fadeOut()
}

let beforeSend = function() {
  $('body').append(filterDiv)
}

class FilterForm extends Widget {
  constructor(root) {
    console.log('filter-form')
    super(root);
    this.formElem = this.root.find('.filters form')

    this.ajaxForm = new AjaxForm(this.formElem)
    this.ajaxForm.on("success",  success.bind(this))
    this.ajaxForm.on("error",    error.bind(this))
    this.ajaxForm.on("before-send", beforeSend.bind(this))

    this.formElem.on('change', '.search-filter', (input) => {
      console.log('send-form')
      this.ajaxForm.doAjax()
    })

    this.formElem.on('change', '.filter-select', (e) => {
      console.log('send-form')
      this.formElem.submit()
    })

    this.formElem.find('.search-multi-select2').select2({
      placeholder: 'Any',
      width: '100%',
      allowClear: true,
      tags: true,
    }).on('change', (e) => {
      this.formElem.submit()
    });

    this.formElem.find('.search-filter-select2').select2({
      placeholder: 'Any',
      allowClear: true,
      width: '100%'
    }).on('change', (e) => {
      this.formElem.submit()
    });
  }

  send() {
    this.formElem.submit();
  }
}

export default FilterForm;
