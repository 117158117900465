import * as initializers0 from '../initializers/input_components/bootstrap.js'; import * as initializers1 from '../initializers/input_components/colorpicker.js'; import * as initializers2 from '../initializers/input_components/datepicker.js'; import * as initializers3 from '../initializers/input_components/input_mask.js'; import * as initializers4 from '../initializers/input_components/jquery-sortable.js'; import * as initializers5 from '../initializers/input_components/radio_buttons.js'; import * as initializers6 from '../initializers/input_components/select2.js'; import * as initializers7 from '../initializers/input_components/switchery.js'; let initializers = [initializers0, initializers1, initializers2, initializers3, initializers4, initializers5, initializers6, initializers7];

let CocoonLoader = function() {
  $('body').on('cocoon:after-insert', (e, item) => {
    if (initializers == undefined) { return };

    initializers.forEach((initializer) => {
      return initializer.default(this);
    });
  })
};

export default CocoonLoader;
