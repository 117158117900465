import BaseController   from "controllers/accounts/base"
import ReportForm       from "widgets/report/form"

class Controller extends BaseController {
  call() {
    super.call();
    new ReportForm(this.root)

  }
}

export default Controller;
