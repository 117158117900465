import BaseController from "controllers/base";
import {RestClient} from "../../../api/rest_client";

export default class Controller extends BaseController {

  call() {
    super.call(this);
    let self = this;

    $("select").select2({placeholder: 'Any', allowClear: false});

    $("#internal-status-update-alert").hide();

    $("#save-internal-status-button").click(function() {
      self.updateVehicle($("#internal-status-selector").select2('data')[0].text)
    });

    $("#new-note-section").hide();
    $("#save-changes-button").hide();

    $("#add-note-button").click(function() {
      $("#add-note-button").hide();
      $("#save-changes-button").show();
      $("#new-note-section").show();
    });

    $("#save-changes-button").click(function() {
      if (!$("#save-changes-button").hasClass('disabled')) {
        self.createNote($("#vehicle-notes-modal").data().vehicleId, $("#vehicle-notes-modal").data().userId, $("#new-note-text").val());
        $("#save-changes-button").addClass('disabled');
      }
    });

    this.updateEnabilityOfSaveChangesButton();
    $("#new-note-text").on('input', function() {
      self.updateEnabilityOfSaveChangesButton();
    })
  }

  updateEnabilityOfSaveChangesButton() {
    if ($("#new-note-text").val().trim() === '') {
      $("#save-changes-button").addClass('disabled')
    } else {
      $("#save-changes-button").removeClass('disabled')
    }
  }

  updateVehicle(internalStatus) {
    RestClient.execute({method: 'put', path: "vehicles/" + $("#vehicle-info-section").data().vehicleId, payload: {internal_status: internalStatus}})
      .success(function(statusCode, responsePayload) {
        $("#internal-status-update-alert").show().delay(3000).fadeOut();
      })
      .failure(function(statusCode, responsePayload) {
        console.log(statusCode, responsePayload);
      });
  }

  createNote(vehicleId, userId, noteText) {
    RestClient.execute({method: 'post', path: "vehicles/" + vehicleId + "/notes", payload: {user_id: userId, note_text: noteText}})
      .success(function(statusCode, responsePayload) {
        $("#save-changes-button").removeClass('disabled');
        $("#add-note-button").show();
        $("#save-changes-button").hide();
        $("#new-note-section").hide();
        location.reload();
      })
      .failure(function(statusCode, responsePayload) {
        console.log(statusCode, responsePayload);
      });
  }

}
