import ReactiveWidget from "lib/reactiveWidget";

class PriceMatrixSearchWidget extends ReactiveWidget {

  bind() {
    this.selected_state   = '';
    this.current_page     = 1;
    this.priceMatrixContainer = this.root.find('#price-matrices-container')

    bindSearchHandlers.call(this);
    handlePaginationLinks.call(this);
  }

  render() {
    fetchData.call(this);
  }
}

let bindSearchHandlers = function() {
  this.searchField        = this.root.find("input[name*=search]:first");

  this.root.on('keyup', this.searchField, (e) => {
    console.info('Text Search Filters Updated');

    delay(() => {
      this.current_page = 1;
      fetchData.call(this);
    }, 500 );
  });
}

let handlePaginationLinks = function() {
  this.root.on('click', '.pagination a', (e) => {
    e.preventDefault();
    e.stopPropagation();

    let href = $(e.target).attr('href');

    this.current_page = getSearchParams(href, 'page');
    fetchData.call(this);
  });
}

let getSearchParams = function(url, k) {
  var p={};
  url.replace(/[?&]+([^=&]+)=([^&]*)/gi,function(s,k,v){p[k]=v})
  return k?p[k]:p;
}

let fetchData = function() {

  let data = {}

  if (_.get(this.state, 'baseUrl', undefined) != undefined) {

    _.assign(data, {
      search: this.searchField.val(),
      page: this.current_page }
    )

    $.ajax({
      url: `${this.state.baseUrl}`,
      method: 'GET',
      dataType: 'JSON',
      data: data,
      beforeSend: () => {
        // add spinners
      },
      success: (data) => {
        // Load the partial
        this.priceMatrixContainer.html(data.partial);

      },
      error: (xhr, text, error) => {
      }
    });

  }
}

export default PriceMatrixSearchWidget;