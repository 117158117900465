import BaseController from "controllers/accounts/base";
import KaminariPagination from "servos/kaminariPagination"
import FilterForm from "servos/filterForm";

import vehicleMovements from "widgets/movements/vehicle_movements";
import BulkReserveUpdate from 'widgets/vehicle/BulkReserveUpdate';
import BulkStatusUpdate from 'widgets/vehicle/BulkStatusUpdate';

export default class Controller extends BaseController {

  call() {
    super.call(this);
    new BulkReserveUpdate(this.root);
    new BulkStatusUpdate(this.root);
    new vehicleMovements(this.root); // the modal to create a new movement
    this.vehiclesContainer = this.root.find('#vehicles-container')
    this.bindSearchList(this.root);
    this.setupHooks()

    $('body').on('partialLoaded', (e, item) => {
      this.setupHooks(this)
    })
  }

  bindSearchList() {
    this.filterForm = new FilterForm(this.root.find('.filtrable'))
    this.kaminariPagination = new KaminariPagination(this.root.find('.paginatable'))

    this.filterForm.on('updated-list', (data) => {
      this.kaminariPagination.trigger('updated-list', data);
      this.setupHooks(this)
    })

    this.kaminariPagination.on("pagination-complete", (data) => {
      // here if I need something on pagination complete
    })

    this.on('select2-changed', (e) => {
      this.filterForm.send()
    });

    this.filterForm.send()
  }

  setupHooks() {
    this.initCheckboxes();
    this.updateBulkActionsButton();
  }

  initCheckboxes() {
    let self = this;

    // When the select all checkbox is clicked
    $("input:checkbox#select-all").click(function() {
      $("input:checkbox.vehicle-list-item").prop("checked", $("input:checkbox#select-all").is(":checked"));
    });

    // When any checkbox is clicked
    this.vehiclesContainer.on('click', "input:checkbox", function() {
      self.updateNumberOfVehiclesSelectedText();
      self.updateMovementCreationUrl();
      self.updateBulkActionsButton();
    })
  }

  updateBulkActionsButton() {
    if ($("input:checkbox.vehicle-list-item:checked").length === 0) {
      $("#bulk-actions-button").addClass('disabled')
    } else {
      $("#bulk-actions-button").removeClass('disabled')
    }
  }

  updateNumberOfVehiclesSelectedText() {
    let numVehiclesSelected = $("input:checkbox.vehicle-list-item:checked").length; + ' vehicles selected.';
    let text = numVehiclesSelected === 1 ? '1 vehicle selected.' : numVehiclesSelected + ' vehicles selected.';
    $("#vehicles-selected-text").text(text);
  }

  updateMovementCreationUrl() {
    $("#create-movement-button").data().url =
      $("#create-movement-button").data().baseUrl +
      $("input:checkbox.vehicle-list-item:checked").toArray().map(item => ('&movements_build[vehicle_ids][]=' + item.id));
  }
}
