/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// import Raven          from "raven-js";
// import configureRaven from "../lib/raven";
import boot from "../lib/boot";
import "trix";

require("jquery");
require("select2");
require("jquery-ui");
require("shopify-jquery-minicolors");
require("@nathanvda/cocoon");
require("jquery-sortable");
require("sweetalert2");

require("@rails/ujs").start();
// require("turbolinks").start()
require("@rails/activestorage").start();

const images = require.context("../images", true);

global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;
const jquery = require("jquery");
const $ = require("jquery");

import "./bootstrap_custom.js";

$(function () {
  // configureRaven();

  // Raven.context(function() {
  boot.init();
});
