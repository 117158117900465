import Widget           from "lib/widget";
import Cookies          from 'js-cookie';
import 'jquery-file-download';
import Swal from "sweetalert2";

class ImagesDownload extends Widget {
  bind() {
    bindDownload.call(this);
  }
}

let bindDownload = function() {
  this.root.on('click', '.download-images', (e) => {
    e.preventDefault();
    let href = e.currentTarget.href;

    $(document.body).css({'cursor': 'wait'});

    $.fileDownload(href, {
      successCallback: function(url) {
        $(document.body).css({'cursor': 'default'});
      },
      failCallback: function(responseHtml, url, error) {
        $(document.body).css({'cursor': 'default'});
        let timeoutMessage = "Sorry, your request timed out. Please try again later."
        let noImagesMessage = "Sorry, There are no images to be downloaded"

        // When requests fail because there are no images we set this flag as 'true'.
        // otherwise we assume the failure is due to a timeout error.
        let noImagesFlag  = Cookies.get('noImagesFlag')

        Swal.fire({
          type: "error",
          text: ((noImagesFlag == 'true') ? noImagesMessage : timeoutMessage)
        })


        Cookies.set('noImagesFlag', 'false')
      }
    })
  })
}

export default ImagesDownload
