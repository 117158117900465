import Widget               from "lib/widget"
import AjaxForm             from "servos/ajaxForm"
import FormLoader           from 'mixins/form'

class priceMatrix extends Widget {
  bind() {
    bindCreatePriceMatrixButtons.call(this)
    bindEditPriceMatrixButton.call(this)
  }
}

let bindCreatePriceMatrixButtons = function() {
  this.root.on('click', "[data-action='create-price-matrix']", (e) => {
    $.ajax({
      url: $(e.target).data('url'),
      method: "GET",
      dataType: "json"
    }).done((response) => {
      $('#modal-container').html(response.html)
      $('#price-matrix.modal').modal('show')

      bindAjaxForm.call(this);
    })
  })
}

let bindEditPriceMatrixButton = function() {
  this.root.on('click', "[data-action='edit-price-matrix']", (e) => {
    $.ajax({
      url: $(e.target).data('url'),
      method: "GET",
      dataType: "json"
    }).done((response) => {
      $('#modal-container').html(response.html)
      $('#price-matrix.modal').modal('show')

      bindAjaxForm.call(this);
    })
  })
}

let bindAjaxForm = function() {
  let form = new AjaxForm($('#price-matrix form'))

  form.on("success", handleFormSuccess.bind(this));
  form.on("error", handleFormError.bind(this));
}

let handleFormSuccess = function(response) {
  window.location = response.redirectUrl;
  window.location.reload(true);
  swal({
    title: "Successfully Updated!"
  })
  $('#edit-inspection-modal').modal('hide');
}

let handleFormError = function(response) {
  this.renderPartial($("#price-matrix form"), response.jqXHR.responseJSON.html)
}

export default priceMatrix;
