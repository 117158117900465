import Widget               from "lib/widget"
import AjaxForm             from "servos/ajaxForm"

class vehicleMovement extends Widget {
  bind() {
    bindSelect2Input.call(this);
    checkVehicleIdSet.call(this);
    bindCompleteMovements.call(this);
    bindCompleteDefleets.call(this);
  }
}

let bindCompleteDefleets = function() {
  this.root.on('click', '.btn-defleet-complete:not(.disabled)', (e) => {
    let url = $(e.currentTarget).data('url');
    let date = $(e.currentTarget).data('date');
    
    swal({
      title:  `${I18n.t('vehicle_movements.complete_defleets.swal.title')}`,
      html:   `<p>${I18n.t('vehicle_movements.complete_defleets.swal.description')}</p><br/><input class='form-control swal-date-input arrival-date' value=${date} data-toggle='datepicker-not-in-future'>`,
      type:   "info",
      showCancelButton: true,
      confirmButtonText: `${I18n.t('vehicle_movements.complete_defleets.swal.action')}`,
      onOpen: () => {
        $('body').trigger('partialLoaded')
      },      
    }).then((Confirm) => {
      console.log(url)
      console.log({arrival_date: $('input.arrival-date').val()})
      $.ajax({
        url: url,
        method: 'PUT',
        dataType: 'json',
        data: {
          arrival_date: $('input.arrival-date').val()
        }
      }).done((response) => {
        window.location.reload();
      });
    }).catch(() => {
      // We don't need to process any dismissals
    })  

  })
}

let bindCompleteMovements = function() {
  this.root.on('click', 'input.complete-movement', (e) => {
    toggleCompleteBtn.call(this, (this.root.find('input.complete-movement:checked').length > 0 ));
  })

  this.root.on('click', '.btn.btn-complete', (e) => {
    let checkedIds = _.map(this.root.find('input.complete-movement:checked'), (n) => { return n.value } )
    let url = $(e.currentTarget).data('url');

    if ( checkedIds.length > 0 ) {
      toggleCompleteBtn.call(this, false)
      
      $.ajax({
        url: url,
        method: "PUT",
        dataType: 'JSON',
        data: {
          vehicle_movements_ids: checkedIds
        }
      }).done((response) => {
        console.log("BANG")
        swal({
          title: "Confimed",
          type: 'success'
        })
        window.location = response.redirectUrl;
        window.location.reload(true);
      })      
    }
  })
}

let toggleCompleteBtn = function(enable) {
  this.root.find('.btn.btn-complete').toggleClass('disabled', !enable)
  this.root.find('.btn.btn-complete').prop('disabled', !enable)
}

let bindSelect2Input = function() {
  this.vehicleSelect = this.root.find('select.vehicle-select')
  this.vehicleSelect.select2({
    placeholder: 'Search Vehicles...',
    minimumInputLength: 3,
    ajax: {
      url: gon.select_2.vehicles,
      datatype: 'json',
      data: (params) => {
        return { q: params.term }
      },
      processResults: (data, page) => {
        return { results: data }
      },
      cache: true
    },
    escapeMarkup: (m) => {
      return m
    }
  });

  this.vehicleSelect.on('select2:select', (e) => {
    let data = data = this.vehicleSelect.select2('data')[0]

    this.root.find('td.rego').html(`<b>${data.object.rego.toUpperCase()}</b>`)
    this.root.find('td.vin').html(`${data.object.vin}`)

    checkVehicleIdSet.call(this)
  });
}

let checkVehicleIdSet = function() {
  let vehicle_id = this.root.find("select.vehicle-select").val()
  if (vehicle_id == '') {
    this.root.find('.new-vehicle').removeClass('d-none')
    this.root.find('.existing-vehicle').addClass('d-none')
  } else {
    this.root.find('.existing-vehicle').removeClass('d-none')
    this.root.find('.new-vehicle').addClass('d-none')
  }
}

export default vehicleMovement;
